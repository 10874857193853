import React, { useState } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [url, setUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [stats, setStats] = useState(null);
  const [shortCode, setShortCode] = useState("");
  const [error, setError] = useState("");

  const shortenUrl = async () => {
    try {
      const response = await axios.post("https://url.pipecdn.app/shorten", { url });
      setShortUrl(response.data.short_url);
      setError("");
    } catch (err) {
      setError("Failed to shorten URL. Please try again.");
    }
  };

  const fetchStats = async () => {
    try {
      const response = await axios.get(`https://url.pipecdn.app/${shortCode}/stats`);
      setStats(response.data);
      setError("");
    } catch (err) {
      setError("Failed to fetch statistics. Please try again.");
    }
  };

  return (
    <div className="App">
      <h1>URL Shortener</h1>

      <div className="shorten">
        <h2>Shorten a URL</h2>
        <input
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button onClick={shortenUrl}>Shorten</button>
        {shortUrl && (
          <p>
            Short URL: <a href={shortUrl}>{shortUrl}</a>
          </p>
        )}
      </div>

      <div className="stats">
        <h2>Get URL Statistics</h2>
        <input
          type="text"
          placeholder="Enter Short Code"
          value={shortCode}
          onChange={(e) => setShortCode(e.target.value)}
        />
        <button onClick={fetchStats}>Fetch Stats</button>
        {stats && (
          <div>
            <p>Total Hits: {stats.hits}</p>
            <h3>IP Hits:</h3>
            <ul>
              {Object.entries(stats.ip_hits).map(([ip, count]) => (
                <li key={ip}>
                  {ip}: {count} hits
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default App;
